<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">
                  Kunlik amortizatsiya xisoblanmagan kunlar
                </h3>
              </div>
              <div class="card-button">
                <v-btn @click="sendDatas" class="mr-2" color="warning" small>
                  Amortizatsiya qilish
                </v-btn>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="list"
                :loading="isLoading"
                :items-per-page="10"
                hide-default-footer
                single-select
                @click:row="rowClick"
                item-key="id"
                class="row-pointer"
                no-data-text="Maʼlumot kiritilmagan"
              >
                <template v-slot:[`item.amortization_not_calced`]="{ item }">
                  <span
                    v-for="(item1, index) in item.amortization_not_calced"
                    :key="index"
                  >
                    {{ item1 }} |||
                    <br v-if="(index + 1) % 2 == 0 && index + 1 !== 1" />
                  </span>
                </template>
                <template v-slot:[`item.amortization_calced`]="{ item }">
                  <span
                    v-for="(item1, index) in item.amortization_calced"
                    :key="index"
                  >
                    {{ item1 }} |||
                    <br v-if="(index + 1) % 6 == 0 && index + 1 !== 1" />
                  </span>
                </template>
                <template v-slot:[`item.calc`]="{ item }">
                  {{ item.amortization_not_calced.length }} |
                  {{ item.amortization_calced.length }}
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      currentPage: 1,
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'Asosiy vosita',
          value: 'name'
        },
        {
          text: 'amortization_not_calced',
          value: 'amortization_not_calced'
        },
        {
          text: 'amortization_calced',
          value: 'amortization_calced'
        },
        {
          text: '/',
          value: 'calc'
        },
        {
          text: 'amortization_all',
          value: 'amortization_all'
        },
        {
          text: 'daily_amortization_amount',
          value: 'daily_amortization_amount'
        },
        {
          text: 'import_date',
          value: 'import_date'
        },
        {
          text: 'report_start_date',
          value: 'report_start_date'
        },
        {
          text: 'report_end_date',
          value: 'report_end_date'
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('getDailyAmortizationUnCalc')
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    sendDatas() {
      const data = {
        main_assets: this.list.map((x) => x.id)
      }
      this.$store.dispatch('createDailyAmortizationUnCalc', data)
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    list() {
      const data = this.$store.state.requests.dailyAmortizationUnCalc
      return data
    }
    // getCount() {
    //   return this.$store.state.requests.incomeList
    // }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Kunlik amortizatsiya xisoblanmagan kunlar' }
    ])
  }
}
</script>
